import {ProjectCarousel} from "./carousel";

var $ = require('jquery');
var jQBridget = require('jquery-bridget');
var Packery = require('packery');
var imagesLoaded = require('imagesloaded');






var Filtered = null;

export const AjaxLoad = (Url, Id, Container, Event) => {

    var AjaxContainer = document.getElementById(Container);
    AjaxContainer.classList.remove("is-loaded");
    var Body = document.body;
    Body.removeAttribute("id");
    Body.setAttribute("id", Id.replace("/", ""));
    AjaxContainer.innerHTML = "";
    AjaxContainer.setAttribute("data-page", Id.replace("/", ""));
    Body.classList.add('ajax-container-visible');
    fetch(Url).then(function (response) {
        return response.json();
    }).then(function (content) {
        AjaxContainer.scrollTop = 0;
        AjaxContainer.innerHTML = content;
        AjaxContainer.classList.add("is-loaded");
        history.pushState(null, null, Id);
        Init();
    });

}

export const Init = () => {

    const grid = document.querySelector('.grid');

    if(grid){
        imagesLoaded( '.grid', function() {
            var pckry = new Packery( '.grid', {
                gutter: '.gutter-sizer',
                columnWidth: '.grid-sizer',
                itemSelector: '.grid-item',
                percentPosition: true,
            });

            pckry.layout();
        });
    }

    const MenuItem = document.querySelectorAll("nav li a");

    const activepage = window.location.pathname;
    const activeMenuLink = document.querySelector("nav li a[href='"+activepage+"']");

    MenuItem.forEach((elem) => {
        elem.classList.remove('active');
    });

    activeMenuLink?.classList.add("active");

    MenuItem.forEach((elem) => {
        elem.addEventListener('click', function () {
            MenuItem.forEach((elem) => {
                elem.classList.remove('active');
            });
            elem.classList.add('active');
        });
    });

    const Filters = document.querySelectorAll(".filters");
    const Containers = document.querySelectorAll(".filtered");

    Filters.forEach((filter) => {
        const targetId = filter.dataset.target;

        filter.addEventListener("click", function (e) {
            e.preventDefault();
            const target = document.getElementById(targetId);
            Filtered = targetId;
            console.log(Filtered);
            Filters.forEach((filter) => {
                filter.classList.remove("active");
            });

            filter.classList.add("active");

            Containers.forEach((container) => {
                container.classList.add("hide");
                container.classList.remove("show");
            });

            target.classList.remove("hide");
            target.classList.add("show");

        });


    });

    const ProjectsItems = document.querySelectorAll('p[data-cover]');

    ProjectsItems.forEach((elem) => {

        elem.addEventListener("mouseenter", () => {
            const cover = elem.dataset.cover;
            const covercontainer = document.querySelector('.cover-container');

            covercontainer.innerHTML = cover;
        });

        elem.addEventListener("mouseleave", () => {
            const cover = elem.dataset.cover;
            const covercontainer = document.querySelector('.cover-container');

            covercontainer.innerHTML = null;
        });
    });

    const InfoItems = document.querySelectorAll('.entry[data-cover]');

    InfoItems.forEach((elem) => {

        elem.addEventListener("mouseenter", () => {
            const cover = elem.dataset.cover;
            const covercontainer = document.querySelector('.cover-container');

            covercontainer.innerHTML = cover;
        });

       /* elem.addEventListener("mouseleave", () => {
            const cover = elem.dataset.cover;
            const covercontainer = document.querySelector('.cover-container');

            covercontainer.innerHTML = null;
        });*/
    });


    const Projects = document.querySelectorAll(".projects-container .load-page");

    Projects.forEach((project) => {
        const Id = project.getAttribute("href");
        const Url = Id + ".json";

        project.addEventListener("click", function (e) {
            e.preventDefault();
            AjaxLoad(Url, Id, "main");
        });
    });


    ProjectCarousel();

    if (Filtered) {
        Filters.forEach((filter) => {
            filter.classList.remove("active");
        });

        Containers.forEach((container) => {
            container.classList.add("hide");
        });

        document.querySelector(".filters[data-target='" + Filtered + "']")?.classList.add("active");
        document.querySelector(".filters[data-target='" + Filtered + "']")?.classList.remove("hide");

        document.getElementById(Filtered)?.classList.remove("hide");
        document.getElementById(Filtered)?.classList.add("show");
    }


}
