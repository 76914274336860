import {ProjectCarousel} from "./_modules/carousel";

__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {Init} from "./_modules/ajaxload";
import Swup from 'swup';

import {Swipers} from "./_modules/carousel";


import {ProjectsMenu} from "./_modules/projectsMenu";
import {MainMenu} from "./_modules/projectsMenu";
import {AjaxLoad} from "./_modules/ajaxload";


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();

  const swup = new Swup({
    containers: ['*[data-swup]'],
    linkSelector: 'a[href]',
  });

  Init();


  swup.hooks.before('content:replace', () => {
    let { swiperH, swiperP } = Swipers();
    if (document.querySelector('.project-swiper')) {
      swiperP.destroy(true, true);
      swiperP = null;
    }
    if (document.querySelector('.home-swiper')) {
      swiperH.destroy(true, true); // Parameters true, true: delete DOM elements and event listeners
      swiperH = null; // Clear reference
    }
  });

  swup.hooks.on('content:replace', () => {
    var id = (swup.location.pathname !== "/") ? swup.location.pathname.replace('/', '') : 'home';
    document.body.setAttribute("id", id);
  }, { before: true });

  swup.hooks.on('page:view', () =>{
    Init();
  });


});