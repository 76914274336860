import Swiper, {Autoplay, EffectFade, Keyboard, Lazy, Manipulation, Mousewheel, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

let swiperH, swiperP;


export const Swipers = () => ({
    swiperH,
    swiperP
});


export const ProjectCarousel = () => {

    swiperH = new Swiper('.home-swiper', {
        modules: [Lazy, Manipulation, EffectFade, Mousewheel, Autoplay, Pagination],
        cssMode: false,
        direction: 'horizontal',
        allowTouchMove: true,
        followFinger: true,
        preloadImages: false,
        resizeObserver: false,
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 300,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {

                if (index + 1 == parseInt(this.slides.length) - 2) {
                    return '<span class="' + className + '">' + "données" + '</span>';
                } else {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                }


            },
        },
        on: {
            lazyImageReady: function () {
                if (this.el.classList.contains("hide")) {
                    var Thumbs = document.querySelectorAll(".thumbs-container img");
                    setTimeout(function () {
                        swiperP.el.classList.remove("hide");
                        Array.from(Thumbs).forEach(function (el) {
                            el.classList.remove("hide");
                        })
                    }, 250);
                }
            },
        }
    });

    swiperP = new Swiper('.project-swiper', {
        modules: [Navigation, Lazy, Manipulation, EffectFade, Mousewheel, Autoplay, Pagination, Keyboard],
        cssMode: false,
        direction: 'horizontal',
        allowTouchMove: true,
        followFinger: true,
        preloadImages: false,
        resizeObserver: false,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1,
            loadOnTransitionStart: true,
        },
        keyboard: {
            enabled: true,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        loop: true,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 300,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {

                if (index + 1 == parseInt(this.slides.length) - 2) {
                    return '<span class="' + className + '">' + "données" + '</span>';
                } else {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                }


            },
        },
        on: {
            lazyImageReady: function () {
                if (this.el.classList.contains("hide")) {
                    var Thumbs = document.querySelectorAll(".thumbs-container img");
                    setTimeout(function () {
                        swiperP.el.classList.remove("hide");
                        Array.from(Thumbs).forEach(function (el) {
                            el.classList.remove("hide");
                        })
                    }, 250);
                }
            },
        }
    });
}